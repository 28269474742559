
















import { Vue, Component, Prop } from 'vue-property-decorator';

export type ScrollBehavior = 'auto' | 'contain' | 'none';

@Component({})
export default class ScrollBar extends Vue {
  @Prop({ type: String, default: '' })
  private wrapStyle?: string;

  @Prop({ type: String, default: '' })
  private wrapClass?: string;

  @Prop({ type: String, default: '' })
  private viewClass?: string;

  @Prop({ type: String, default: '' })
  private viewStyle?: string;

  @Prop({ type: Boolean, default: false })
  private flexLayout?: boolean;

  @Prop({ type: String, default: 'auto' })
  private overscrollBehavior?: ScrollBehavior;

  mounted() {
    const scrollEl = (this.$refs.scrollBar as any).wrap;
    scrollEl.onscroll = () => {
      this.$emit('scroll', scrollEl.scrollTop);
    };
  }

  getScrollEl() {
    return (this.$refs.scrollBar as any).wrap;
  }
}
