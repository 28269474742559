<template>
  <div class="my-live">
    <div class="radio-header">
      <span class="title">我的LIVE</span>
      <el-radio-group
        v-model="activePage"
        size="mini"
        class="switch-tab"
        @change="changeActivePage"
      >
        <el-radio-button
          label="SUBSCRIBE"
          border
        >
          我的预约
        </el-radio-button>
        <el-radio-button
          label="PARTAKE"
          border
        >
          我的参与
        </el-radio-button>
        <el-radio-button
          label="RELEASE"
          border
        >
          我的发布
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="tab-header">
      <el-tabs
        v-model="activeName"
      >
        <el-tab-pane
          label="全部直播"
          name="0"
        />
        <el-tab-pane
          label="预约中"
          name="1"
        />
        <el-tab-pane
          label="直播中"
          name="2"
        />
        <el-tab-pane
          label="直播回放"
          name="3"
        />
        <el-tab-pane
          v-if="activePage === 'RELEASE'"
          label="待审核"
          name="4"
        />
        <el-tab-pane
          v-if="activePage === 'RELEASE'"
          label="审核驳回"
          name="5"
        />
      </el-tabs>
    </div>
    <infinite-scroll
      :key="active"
      :load-more-fun="getMyLive"
      :loading.sync="loading"
      :page.sync="params.page"
      :page-size="params.pageSize"
      :total="total"
      class="mutual-follow-container"
    >
      <div
        v-if="liveList.length"
        class="live-lists"
      >
        <div
          v-for="(liveItem,index) in liveList"
          :key="liveItem.id"
          class="live-item"
        >
          <div
            class="live-posters"
            :class="getLabelContent(liveItem).able"
            @click="handleJump(liveItem)"
          >
            <img :src="liveItem.posterUrl">

            <div
              v-if="liveItem.liveRoomTypeEnum === 'ONLINE'"
              class="label-wrapper-online"
            >
              <img
                class="live-gif"
                src="@/assets/img/LarkLive.gif"
              >
              直播中
            </div>
            <div
              v-else
              class="label-wrapper"
            >
              <i :class="getLabelContent(liveItem).icon" />
              {{ getLabelContent(liveItem).text }}
            </div>
          </div>
          <div
            class="label-group"
            @click="handleJump(liveItem)"
          >
            <div
              v-if="liveItem.experienceStatus"
              class="live-experience"
            >
              体验
            </div>
            <overflow-tool-tip
              tool-tip-class="_home-more-live-tooltip_"
              :content="liveItem.liveTheme"
            >
              <div
                class="live-title"
                :class="getLabelContent(liveItem).able"
              >
                {{ liveItem.liveTheme || '-' }}
              </div>
            </overflow-tool-tip>
          </div>
          <div class="live-detail">
            <div
              class="live-airtime"
              :style="`color: ${liveItem.liveRoomTypeEnum==='SUBSCRIBED'? '#FD7915': ''}`"
            >
              <i class="iconfont icon-v_newsflash" />
              {{ getAirtimeText(liveItem) }}
            </div>
            <div
              v-if="liveItem.type!==4 && liveItem.type!==5"
              class="appointment-count"
            >
              <i
                v-if="liveItem.totalViewCount < 100000"
                class="appointment-icon iconfont icon-v_hot"
              />
              <img
                v-else
                class="appointment-icon"
                src="@/assets/img/hot.svg"
              >
              {{ getAppointNumber(liveItem) }}
              人{{ getAppointmentText(liveItem) }}
            </div>
          </div>
          <div class="live-operation-btn">
            <el-button
              v-if="liveItem.type ===2 || (liveItem.type ===1 && activePage==='RELEASE')"
              type="primary"
              size="small"
              class="watch-btn subscribe-btn"
              :disabled="liveItem.startAble || (activePage==='RELEASE' && liveItem.showStatus===2)"
              @click="handleOperation(liveItem,index)"
            >
              {{ getBtnContent(liveItem) }}
            </el-button>
            <el-button
              v-else
              size="small"
              :type="liveItem.type===5? 'danger':'default'"
              class="watch-btn subscribe-btn"
              :disabled="(activePage!=='RELEASE'&&liveItem.type===1)
                || (activePage==='RELEASE' && liveItem.type===4)
                || (activePage==='RELEASE' && liveItem.showStatus===2)
                || liveItem.startAble"
              plain
              @click="handleOperation(liveItem,index)"
            >
              {{ getBtnContent(liveItem) }}
            </el-button>
            <el-button
              v-if="liveItem.joinRoomType===1 && activePage==='RELEASE'
                && liveItem.showStatus !== 2
                && liveItem.showStatus !== 0"
              size="small"
              class="watch-btn subscribe-btn"
              plain
              @click="handleApplyDialogVisible(liveItem)"
            >
              处理申请
            </el-button>
          </div>
        </div>
      </div>
      <div
        v-else
        slot="emptyContent"
      >
        <empty-content
          class="empty-content"
        >
          <div
            slot="message"
            class="blank-massage"
          >
            <span v-if="activePage==='SUBSCRIBE' && activeName === '0'">
              您暂未预约过直播，
              <a @click="goToLink('home')">点击此处去预约</a>
            </span>
            <span v-if="activePage==='PARTAKE' && activeName === '0'">
              您暂未参与过直播，
              <a @click="goToLink('home')">点击此处去参与</a></span>
            <span
              v-if="activePage==='RELEASE'
                && (isEnterpriser || isInvestor || isOrg) && activeName === '0'"
            >
              您暂未发布过直播，
              <a @click="goToLink('publish')">点击此处去发布</a></span>
            <span
              v-if="activePage==='RELEASE'
                && !(isEnterpriser || isInvestor || isOrg) && activeName === '0'"
            >
              创投live仅认证用户可发起，点击相应身份立即认证
            </span>
            <span v-if="activeName !== '0'">
              暂无数据
            </span>
            <div
              v-if="activePage==='RELEASE'
                && !(isEnterpriser || isInvestor || isOrg) && activeName === '0'"
              class="profile-need"
            >
              <span><a @click="goToLink('EntrepreneurAuth')">我是创业者</a></span>
              <span>｜</span>
              <span><a @click="goToLink('InvestorAuth')">我是投资人</a></span>
            </div>
          </div>
        </empty-content>
      </div>
    </infinite-scroll>
    <apply-dialog
      v-if="applyDialog"
      :room-id="applyId"
      :visible="applyDialog"
      :theme="applyTheme"
      @close="applyDialog = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import InfiniteScroll from '@/views/components/InfiniteScroll';
import { myLive, startLive } from '@/services/user';
import { getCloudToken } from '@/services/home';
import { cloudRequestPath, environmentHref } from '@/utils/cloudRequest';

export default {
  name: 'Index',
  components: {
    InfiniteScroll,
    EmptyContent: () => import('@/components/EmptyContent'),
    OverflowToolTip: () => import('@/components/OverflowToolTip'),
    ApplyDialog: () => import('./components/ApplyDialog'),
  },
  data() {
    return {
      activePage: 'SUBSCRIBE', // 'SUBSCRIBE','PARTAKE','RELEASE'
      activeName: '0',
      loading: false,
      params: {
        page: 0,
        pageSize: 15,
        myLiveTypeEnum: 'SUBSCRIBE',
        type: '0',
      },
      total: 0,
      liveList: [],
      applyDialog: false,
      applyId: '',
      applyTheme: '',
      timer: null,
    };
  },
  computed: {
    ...mapState('user', ['isLogin']),
    ...mapGetters('user', ['isEnterpriser', 'isInvestor', 'isOrg']),
    active() {
      return this.activeName + this.params.myLiveTypeEnum;
    },
  },
  watch: {
    activeName() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.params.page = 0;
      this.total = 0;
      this.liveList = [];
      this.params.type = this.activeName;
    },
  },
  mounted() {
    if (!this.isLogin) {
      const routes = this.$router.resolve({
        path: '/home',
      });
      window.open(routes.href, '_self');
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    ...mapActions('login', ['showLoginDialog']),
    changeActivePage(val) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.params.page = 0;
      this.total = 0;
      this.activeName = '0';
      this.params.myLiveTypeEnum = val;
      this.params.type = '0';
      this.liveList = [];
    },
    async getMyLive() {
      if (!this.isLogin) {
        const routes = this.$router.resolve({
          path: '/home',
        });
        window.open(routes.href, '_self');
      }
      this.loading = true;
      let params = {};
      const originalTotal = this.total;
      const toNext = () => {
        params = {
          ...this.params,
          page: this.params.page + 1,
        };
        this.total = originalTotal;
      };
      toNext();
      try {
        const res = await myLive(params);
        this.loading = false;
        if (res.data?.length === 0) return;
        this.params.page += 1;
        this.total = res.totalCount;
        this.liveList = this.liveList.concat(res.data);
        this.handleStartAble();
      } finally {
        //
      }
    },
    // 处理开始直播的时间限制
    handleStartAble() {
      // 开始直播仅在我的发布-全部/预约中
      const { currentServerTimeMillis } = this.liveList[0];
      let nowTime = currentServerTimeMillis;
      if (this.activePage === 'RELEASE' && (this.activeName === '0' || this.activeName === '1')) {
        this.liveList.forEach((item, index) => {
          if (item.type === 1) { // 开始直播-前一个小时后20分钟
            if (item.preStartTime >= currentServerTimeMillis + 3600 * 1000
                || item.preStartTime + 1200 * 1000 < currentServerTimeMillis) {
              this.liveList[index].startAble = true;
            }
          }
        });
        this.$forceUpdate();
        this.timer = setInterval(() => {
          nowTime += 1000;
          this.liveList.forEach((item, index) => {
            if (item.type === 1) { // 开始直播-前一个小时后20分钟
              this.liveList[index].startAble = item.preStartTime >= nowTime + 3600 * 1000
                  || item.preStartTime + 1200 * 1000 < nowTime;
            }
          });
          this.$forceUpdate();
          const t = this.liveList.filter((itm) => { return itm.type === 1; });
          if (t.length < 0) {
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },
    // 获取查看回放的人数或者预约的人数
    getAppointNumber(liveItem = {}) {
      const { liveRoomTypeEnum, preCount, totalViewCount } = liveItem;
      let count = '';
      if (liveRoomTypeEnum === 'SUBSCRIBED') {
        count = preCount;
      } else {
        count = totalViewCount;
      }
      return count >= 100000 ? '10w+' : count;
    },
    // 获取图片上标签的文案和图标
    getLabelContent(liveItem = {}) {
      const { liveRoomTypeEnum, status, showStatus } = liveItem;
      if (liveRoomTypeEnum === 'SUBSCRIBED' && showStatus !== 2) {
        return { icon: 'iconfont icon-yuyue', able: 'able', text: '预约中' };
      }
      if (liveRoomTypeEnum === 'CLOSED' && showStatus !== 2) {
        return { icon: 'iconfont icon-huifang', able: 'able', text: '直播回放' };
      }
      if (liveRoomTypeEnum === 'ONLINE' && showStatus !== 2) {
        return { text: '直播中', able: 'able' };
      }
      if (liveRoomTypeEnum === 'NOONLINE' && status === 0 && showStatus !== 2) {
        return { icon: 'iconfont icon-shenhe', text: '审核中', able: 'disable' };
      }
      if (liveRoomTypeEnum === 'NOONLINE' && status === 2 && showStatus !== 2) {
        return { icon: 'iconfont icon-bohui', text: '审核驳回', able: 'able' };
      }
      if (showStatus === 2) {
        return { icon: 'iconfont icon-bohui', text: '已下架', able: 'disable' };
      }
    },
    // 获取播放时间的文案
    getAirtimeText(liveItem = {}) {
      const {
        liveRoomTypeEnum, startTime, preStartTime, type,
      } = liveItem;
      let content = '';
      if (liveRoomTypeEnum === 'SUBSCRIBED' || type === 4 || type === 5) {
        content = `${moment(preStartTime).format('YYYY-MM-DD HH:mm')} 开播`;
      } else {
        content = moment(startTime).format('YYYY-MM-DD');
      }
      return content;
    },
    // 获取观看人数的文案
    getAppointmentText(liveItem = {}) {
      const { liveRoomTypeEnum } = liveItem;
      if (liveRoomTypeEnum === 'SUBSCRIBED') {
        return '预约';
      }
      if (liveRoomTypeEnum === 'CLOSED') {
        return '观看';
      }
      if (liveRoomTypeEnum === 'ONLINE') {
        return '围观';
      }
    },
    // 处理banner和title的跳转
    handleJump(liveItem = {}) {
      const {
        id, liveRoomTypeEnum, type, showStatus,
      } = liveItem;
      let routeType = '';
      // 预约
      if (liveRoomTypeEnum === 'SUBSCRIBED' && showStatus !== 2) {
        routeType = 'live';
      }
      // 回放
      if (liveRoomTypeEnum === 'CLOSED' && showStatus !== 2) {
        routeType = 'replay';
      }
      if (liveRoomTypeEnum === 'ONLINE' && showStatus !== 2) {
        routeType = 'liveRoom';
      }
      if (routeType) {
        const routes = this.$router.resolve({
          path: `/${routeType}/${id}`,
        });
        window.open(routes.href, '_blank');
      }
      // 审核驳回
      if (type === 5) {
        const routes = this.$router.resolve({
          path: `/publish?id=${id}`,
        });
        window.open(routes.href, '_blank');
      }
    },
    // 处理button文案
    getBtnContent(liveItem = {}) {
      const { type, showStatus } = liveItem;
      if (type === 3 && showStatus !== 2) {
        return '观看回放';
      }
      if (this.activePage === 'RELEASE') {
        if (showStatus !== 2) {
          // eslint-disable-next-line default-case
          switch (type) {
          case 1:
            return '开始直播';
          case 2:
            return '进入直播';
          case 4:
            return '审核中';
          case 5:
            return '重新编辑';
          }
        } else {
          return '已下架';
        }
      } else {
        if (type === 1) {
          return '已预约';
        }
        if (type === 2) {
          return '立即观看';
        }
      }
    },
    // 处理button事件
    async handleOperation(liveItem = {}, index) {
      const { type, id } = liveItem;
      if (this.activePage === 'RELEASE' && type === 1) {
        try {
          await startLive({
            liveRoomId: id,
          });
          this.liveList[index].type = 2;
          this.$forceUpdate();
          const routes = this.$router.resolve({
            path: `/liveroom/${id}`,
          });
          window.open(routes.href, '_blank');
          return;
        } finally {
          //
        }
      }
      if (type === 3) {
        const routes = this.$router.resolve({
          path: `/replay/${id}`,
        });
        window.open(routes.href, '_blank');
        return;
      }
      if (type === 2) {
        const routes = this.$router.resolve({
          path: `/liveroom/${id}`,
        });
        window.open(routes.href, '_blank');
        return;
      }
      if (type === 5) {
        const routes = this.$router.resolve({
          path: `/publish?id=${id}`,
        });
        window.open(routes.href, '_blank');
      }
    },
    async goToLink(path) {
      if (['home', 'publish'].includes(path)) {
        const host = window.location.host;
        window.open(`https://${host}/#/${path}`, '_blank');
      }
      if (this.activePage === 'RELEASE' && (!this.isEnterpriser || !this.isInvestor)) {
        try {
          const token = await getCloudToken();
          const fullPath = `${environmentHref}/#/financing/personalCenter?page=${path}`;
          window.open(`${cloudRequestPath}&token=${token}&redirectUrl=${fullPath}`);
        } catch (error) {
          // console.log(error)
        }
      }
    },
    handleApplyDialogVisible(liveItem = {}) {
      this.applyDialog = true;
      this.applyTheme = liveItem.liveTheme;
      this.applyId = liveItem.id;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index";
</style>
<style lang="scss">
.my-live {
  .scroll-no-more {
    display: none;
  }
}

._home-more-live-tooltip_ {
  &.is-dark {
    background: #546880 !important;
  }

  .popper__arrow {
    border-bottom-color: #546880 !important;
    border-top-color: #546880 !important;

    &::after {
      border-bottom-color: #546880 !important;
      border-top-color: #546880 !important;
    }
  }
}
</style>
