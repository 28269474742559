<template>
  <div class="blank-wrapper">
    <img
      v-if="type === 'default'"
      class="blank-img"
      src="@/assets/img/blank_nodata@2x.png"
    >
    <img
      v-if="type === 'search'"
      class="blank-img"
      src="@/assets/img/no-search.png"
    >
    <div class="blank-text">
      <slot
        v-if="$slots.message"
        name="message"
      />
      <template v-else>
        {{ text }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    text: {
      type: String,
      default: '暂时没有相关数据',
    },
  },
};
</script>

<style lang="scss" scoped>
.blank-wrapper {
  text-align: center;
  padding-top: 24px;
  margin-bottom: 34px;
  line-height: normal;

  & .blank-img {
    width: 80px;
  }

  & .blank-text {
    color: #c1c4cb;
    font-size: 14px;

    & /deep/ {
      a {
        cursor: pointer;
      }
    }
  }
}
</style>
