<template>
  <scroll-bar
    ref="infiniteScroll"
    v-loading="firstPageLoading"
    class="infinite-scroll"
    wrap-class="infinite-scroll-wrapper"
    view-class="infinite-scroll-view"
  >
    <!-- 第一页加载时不展示列表 -->
    <div
      v-if="!firstPageLoading"
      class="scroll-list-wrapper"
    >
      <slot />
    </div>

    <template v-if="$slots.emptyContent">
      <slot
        v-if="isEmpty"
        name="emptyContent"
      />
    </template>
    <template v-else>
      <empty-content
        v-if="isEmpty"
        class="empty-content"
        :text="emptyText"
      />
    </template>

    <!-- 定制加载中、加载完成使用插槽实现 -->
    <div
      v-if="total && scrollLoading"
      class="scroll-loading"
    >
      <img
        src="@/assets/img/loading.gif"
        alt="loading"
      >
      正在加载...
    </div>
    <div
      v-if="total && noMore"
      class="scroll-no-more"
    >
      已无更多数据
    </div>
  </scroll-bar>
</template>

<script>
import _ from 'lodash';
import EmptyContent from '@/components/EmptyContent';
import ScrollBar from './ScrollBar';

export default {
  name: 'InfiniteScroll',
  components: {
    ScrollBar,
    EmptyContent,
  },
  props: {
    pageSize: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadMoreFun: {
      type: Function,
      required: true,
    },
    initLoad: {
      type: Boolean,
      default: true,
    },
    isShowEmptyContent: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    emptyText: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      // @todo 可能初始化就是inactive状态
      inactive: false,
    };
  },

  computed: {
    noMore() {
      return this.page * this.pageSize >= this.total;
    },
    scrollLoading() {
      return !this.noMore && this.page !== 0;
    },
    firstPageLoading() {
      return this.page === 0 && this.loading;
    },

    isEmpty() {
      return this.total === 0 && !this.loading && this.isShowEmptyContent;
    },
  },

  activated() {
    this.inactive = false;
  },
  deactivated() {
    this.inactive = true;
  },

  mounted() {
    const rootEl = this.$refs.infiniteScroll.$el;
    const scrollEl = rootEl.querySelector('.infinite-scroll-wrapper');

    scrollEl.addEventListener('scroll', this.handleScroll, false);

    if (this.initLoad) {
      this.loadData();
    }
  },

  beforeDestroy() {
    const rootEl = this.$refs.infiniteScroll.$el;
    const scrollEl = rootEl.querySelector('.infinite-scroll-wrapper');

    scrollEl.removeEventListener('scroll', this.handleScroll, false);
  },

  methods: {
    checkScrollBottom() {
      const rootEl = this.$refs.infiniteScroll.$el;
      const scrollEl = rootEl.querySelector('.infinite-scroll-wrapper');

      const { clientHeight, scrollHeight, scrollTop } = scrollEl;
      // 如果没有高度，可能为非active状态，不加载
      if (scrollHeight === 0) {
        return;
      }
      return scrollHeight <= clientHeight + scrollTop + 10;
    },

    handleScroll: _.debounce(async function () {
      if (this.disabled) return;
      if (this.loading) return;
      if (this.noMore) return;
      if (this.inactive) return;

      if (this.checkScrollBottom()) {
        this.loadData();
      }
    }, 500),

    async loadData() {
      this.$emit('update:loading', true);
      try {
        // const res =
        await this.loadMoreFun();
        // 如果加载不足一屏，再次触发load
        this.$nextTick(() => {
          if (this.noMore) return;

          if (this.checkScrollBottom()) {
            this.handleScroll();
          }
        });
      } catch (error) {
        // 无需处理
      }
      this.$emit('update:loading', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.infinite-scroll {
  height: 100%;
}

.init-loading {
  height: 100%;
}

.empty-content {
  text-align: center;
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll-loading,
.scroll-no-more {
  font-size: 12px;
  color: #959aa2;
  padding: 16px 24px 32px;

  > i {
    margin-right: 4px;
  }

  > img {
    width: 22px;
    vertical-align: bottom;
  }
}
</style>
